<template src="./main.html"></template>


<script>
import { mapState } from "vuex";

import LeftBar from "@/components/layout/LeftBar";
import ToolBar from "@/components/layout/ToolBar";

export default {
  components: {
    LeftBar,
    ToolBar,
  },

  data: () => ({
    menu: [],
    disabilitaBtn: false,
  }),

  methods: {
    async init() {
      try {
        // User info
        let res = await this.$store.dispatch("user/userInfo");

        this.menu = res.data.menu;
      } catch (error) {
        console.log(this.$functions.getErrorMsg(error));
        this.$toast.info(this.$functions.getErrorMsg(error));
        this.esci();
      }
    },

    async verificaPermessi() {
      if (!await this.$store.dispatch("auth/checkPage", window.location.pathname)) {
        this.$router.push({ path: "/" });
      }
    },

    esci() {
      this.disabilitaBtn = true;

      try {
        this.$store.dispatch("auth/logout");
      } catch (error) {
        console.log(this.$functions.getErrorMsg(error));
      }
    },

    showLeftBar() {
      this.$refs.leftBar.leftBarVis = !this.$refs.leftBar.leftBarVis;
    },
  },

  mounted() {
    this.init();
    this.verificaPermessi();
  },

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
    }),

    utente() {
      return this.$store.getters["user/getNome"];
    },
  },
};
</script>
