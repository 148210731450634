import { Bar } from 'vue-chartjs';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

export default {
    extends: Bar,

    props: ["data", "options"],

    components: {
        ChartJsPluginDataLabels,
    },

    mounted() {
        // Renderizza il grafico misto
        this.addPlugin(ChartJsPluginDataLabels); // Aggiunge il plugin opzionale
        this.renderChart(this.data, this.options);
    },
};
