<template src="./fatture.html"></template>

<script>
import { get_data, get_dettaglioDocu } from "@/api/api.documenti.js";
import { get_clienti } from "@/api/api.anagrafica.js";
//import { get_utenti } from "@/api/api.calendar.js";

import mixinDocu from "./mixinDocu.js";

import GraficoFatturato from "@/components/dialog/documenti/GraficoFatturato";

export default {
  name: "PageFatture",

  mixins: [mixinDocu],

  components: {
    GraficoFatturato
  },

  data: () => ({
    filtri: {
      anno: new Date().getFullYear(),
      cdContatto: "",
      raggruppaClienti: false,
      meseStr: null,
      mese: null,
      operatore: [],
      dataInizio: "",
      dataInizioIT: "",
      dataFine: "",
      dataFineIT: "",
    },
    search: "",
    elencoAnni: [],
    elencoClienti: [],
    elencoMesi: [],
    elencoOperatori: [],

    elencoDati: [],
    headersDati: [],
    dettaglioDocu: [],
    headersDettaglio: [],

    expanded: [],
    importoTotale: 0,
    ivaTotale: 0,

    dialogGrafico: false,

    calendarDataDa: false,
    calendarDataA: false,
    calendarDataDaM: false,
    calendarDataAM: false,

    isExpanded: false,

    opzioni: [
      { id: 0, testo: "" },
      { id: 1, testo: "Mese corrente" },
      { id: 2, testo: "1° Trimestre" },
      { id: 3, testo: "2° Trimestre" },
      { id: 4, testo: "3° Trimestre" },
      { id: 5, testo: "4° Trimestre" },
      { id: 6, testo: "1° Quadrimestre" },
      { id: 7, testo: "2° Quadrimestre" },
      { id: 8, testo: "3° Quadrimestre" },
      { id: 9, testo: "1° Semestre" },
      { id: 10, testo: "2° Semestre" }],
    periodoSel: "",

    altezzaTabella: "69vh",
  }),

  methods: {
    async caricaDati() {
      if (!this.filtri.raggruppaClienti)
        this.headersDati = this.getHeadersDati();
      else
        this.headersDati = this.getHeadersDatiGruppo();

      this.headersDettaglio = this.getHeadersDettaglio(this.filtri.raggruppaClienti);

      this.filtri.mese = this.elencoMesi.indexOf(this.filtri.meseStr);
      let res = await get_data("fatture/elenco", this.filtri);
      this.elencoDati = res.data;

      this.importoTotale = 0;
      this.ivaTotale = 0;
      this.elencoDati.forEach((el) => {
        this.importoTotale += el.importoTot;
        this.ivaTotale += el.totIva;
      });
      this.importoTotale = this.$functions.numberWithCommas(this.importoTotale.toFixed(2));
      this.ivaTotale = this.$functions.numberWithCommas(this.ivaTotale.toFixed(2));
    },

    caricaAnni() {
      for (let i = this.filtri.anno; i >= 2019; i--) {
        this.elencoAnni.push(i);
      }
    },

    /* async caricaOperatori() {
      let res = await get_utenti();
      this.elencoOperatori = res.data;
    }, */

    async caricaClienti() {
      let res = await get_clienti();
      this.elencoClienti = res.data;
    },

    refresh() {
      this.caricaDati();
    },

    async caricaInfo(item) {
      this.elencoDati.forEach((el) => {
        if (el != item) el.expanded = false;
      });
      item.expanded = !item.expanded;

      this.expanded = [];
      if (item.expanded) {
        this.expanded.push(item);
        let res = {};
        let mese = this.filtri.mese + 1;
        if (!this.filtri.raggruppaClienti)
          res = await get_dettaglioDocu("fatture", item.nrDocu + "/" + mese);
        else
          res = await get_dettaglioDocu("fatture/gruppo/" + item.codAnagrafica, this.filtri.anno + "/" + mese)
        this.dettaglioDocu = res.data;
      } else this.dettaglioDocu = [];
    },

    getTotRigo(item) {
      return this.$functions.numberWithCommas(
        (item.prUnitario * item.qtOrdinata).toFixed(2)
      );
    },

    mostraGrafico() {
      this.dialogGrafico = true;
    },

    chiudiGrafico() {
      this.dialogGrafico = false;
    },

    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) this.altezzaTabella = "60vh"
      else this.altezzaTabella = "69vh";
    },

    impostaData() {
      // Data corrente
      let oggi = new Date();

      const primoGiornoDelMese = (anno, mese) => new Date(Date.UTC(anno, mese, 1)).toISOString().substring(0, 10);
      const ultimoGiornoDelMese = (anno, mese) => new Date(Date.UTC(anno, mese + 1, 0)).toISOString().substring(0, 10);

      if (!this.periodoSel) {
        this.filtri.dataInizio = null;
        this.filtri.dataFine = null;
        return;
      }

      switch (this.periodoSel.id) {
        case 1:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), oggi.getMonth());
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), oggi.getMonth());
          break;

        case 2:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 0);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 2);
          break;

        case 3:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 3);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 5);
          break;

        case 4:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 6);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 8);
          break;

        case 5:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 9);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 11);
          break;

        case 6:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 0);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 3);
          break;

        case 7:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 4);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 7);
          break;

        case 8:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 8);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 11);
          break;

        case 9:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 0);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 5);
          break;

        case 10:
          this.filtri.dataInizio = primoGiornoDelMese(oggi.getFullYear(), 6);
          this.filtri.dataFine = ultimoGiornoDelMese(oggi.getFullYear(), 11);
          break;

        case 0:
        default:
          this.filtri.dataInizio = null;
          this.filtri.dataFine = null;
          break;
      }
    },
  },

  mounted() {
    this.caricaAnni();
    this.caricaClienti();
    this.caricaDati();
  },

  created() {
    this.headersDati = this.getHeadersDati();
    this.elencoMesi = this.$i2kFunctions.getMonths();
    //this.caricaOperatori();
  },

  watch: {
    "filtri.dataInizio": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataFine != null &&
        this.filtri.dataFine != "" &&
        newVal > this.filtri.dataFine
      ) {
        this.filtri.dataInizio = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataInizioIT = this.$i2kFunctions.formatISODate(
        this.filtri.dataInizio
      );
    },

    "filtri.dataFine": function (newVal, oldVal) {
      if (
        newVal != null &&
        newVal != "" &&
        this.filtri.dataInizio != null &&
        this.filtri.dataInizio != "" &&
        newVal < this.filtri.dataInizio
      ) {
        this.filtri.dataFine = oldVal;
        this.$toast.alert("Data non valida");
      }
      this.filtri.dataFineIT = this.$i2kFunctions.formatISODate(this.filtri.dataFine);
    },
  }
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>