export default {

    DateFormat(now) {
        let year = "" + now.getFullYear();
        let month = "" + (now.getMonth() + 1);
        if (month.length == 1) {
            month = "0" + month;
        }
        let day = "" + now.getDate();
        if (day.length == 1) {
            day = "0" + day;
        }
        let hour = "" + now.getHours();
        if (hour.length == 1) {
            hour = "0" + hour;
        }
        let minute = "" + now.getMinutes();
        if (minute.length == 1) {
            minute = "0" + minute;
        }
        let second = "" + now.getSeconds();
        if (second.length == 1) {
            second = "0" + second;
        }

        return day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + second;
    },

    formatISODate(data) {
        // NOTA: la data deve essere passata in formato ISO (.toISOString())
        if (!data) return null;

        // Ricavo solo la data (primi 10 caratteri)
        data = data.substr(0, 10)

        const [year, month, day] = data.split("-");
        return `${day}/${month}/${year}`;
    },

    getErrorMsg(err) {
        // NOTA: quando non c'è la rete, err.response è undefined
        //       quando la pagina non è trovata (404) "data" è vuoto
        //       quando da Api non passo "message" prendo "data"
        return (err.response && err.response.data ? (err.response.data.message || err.response.data) : err.message)
    },

    numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    },

    getWeekDay(dateString) {
        var days = ['domenica', 'lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato'];
        let d = new Date(dateString);
        return days[d.getDay()];
    },
}