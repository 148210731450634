<template>
  <v-navigation-drawer fixed width="220" :clipped="$vuetify.breakpoint.mdAndUp" break-point="768" app
    v-model="leftBarVis">
    <v-list dense two-line class="pa-0">
      <template v-for="(item) in menuItems">
        <v-list-item :key="item.text" @click="gotoPage(item)" :style="{
          borderLeft:
            item.link === $route.path
              ? '4px solid #FAB900'
              : '4px solid #424242',
          color: 'secondary',
        }">
          <v-list-item-avatar>
            <v-icon large :color="item.link === $route.path ? 'tertiary' : item.color">
              {{ item.img }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title style="font-size: 15px">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider color="#BDBDBD" :key="'divider_' + item.progOrder"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>


<script>
export default {
  props: {
    menuItems: {
      type: Array,
    },
  },
  data: () => ({
    leftBarVis: null,
  }),

  methods: {
    esci() {
      this.$emit("esci");
    },

    gotoPage(item) {
      if (!this.$store.dispatch("auth/checkPage", window.location.pathname)) {
        this.$router.push({ path: "/" });
        return;
      }

      if (item.link == "@esci") this.esci();
      else {
        this.$router.push({ path: item.link });
      }
    },
  },
};
</script>
