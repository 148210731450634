import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Toast from 'i2kjslibrary/plugins/vuetify/toast/toast.js';
import Confirm from 'i2kjslibrary/plugins/vuetify/confirm/confirm.js';
import Wait from 'i2kjslibrary/plugins/vuetify/wait/wait';

import i2kFunctions from 'i2kjslibrary/functions';

import Functions from "@/utils/functions.js";
import Load from "./LOAD.js"
import wb from "./registerServiceWorker";

import "./assets/css/style.css"

//import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import {
  VTooltip,
} from 'floating-vue'
Vue.prototype.$workbox = wb;

Vue.directive('tooltip', VTooltip)

// Vuetify
Vue.use(Vuetify)

// Plugins
Vue.use(Toast, {
  version: 2
})
Vue.use(Confirm, {
  version: 2
})
Vue.use(Wait, {
  version: 2
})

//Vue.use(FloatingVue)


// Prototype
Vue.prototype.$functions = Functions;
Vue.prototype.$i2kFunctions = i2kFunctions;
Vue.prototype.$load = Load;

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'md',
    },
    theme: {
      /* options: {
        themeCache: {
          get: key => localStorage.getItem(key),
          set: (key, value) => localStorage.setItem(key, value),
        },
      },
     */
      themes: {
        light: {
          primary: '#1D6675',
          secondary: '#424242',
          tertiary: '#FAB900',
          accent: '#8c9eff',
          error: '#b71c1c',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
        dark: {
          primary: '#1D6675',
          secondary: '#FFF',
          tertiary: '#FAB900',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        }
      },
    },
  })
}).$mount('#app')